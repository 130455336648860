<template>
  <v-footer class="pa-3">
    Developed with
    <v-icon small color="red">fas fa-heart</v-icon> and
    <v-icon small color="green">fab fa-vuejs</v-icon>
    <v-spacer></v-spacer>
    <div>
      &copy; {{ new Date().getFullYear() }} Anushasan Poudel. All rights
      reserved.
    </div>
  </v-footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
